@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav {
        flex-direction: row !important;
    }
  }
  .bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color:rgb(1,4,9) !important;
  }
   .navbar{
    padding-right:10px !important ;
    --bs-navbar-nav-link-padding-x: 1rem !important;
  }
  @media (max-width:992px){
    .navbar-toggler {
      background-color: rgb(230,237,243) !important;
  }
  }