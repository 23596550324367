.homeMain{
    display: flex;
}
.homeContainer{
    /* margin-top: -20px; */

/* background-color: rgb(13,17,23); */
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}
.videoMain{
    text-align: left;
    margin-top: 10px;
    padding: 20px;
    /* border: 1px solid rgb(230,237,243); */
    text-align: center;
    /* color: rgb(230,237,243); */
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}
.buttonMain{
    margin-top: 40px;
    margin-bottom: 28px;
    display: flex;
    justify-content: center;
    align-items:center ;
    gap: 10px;
    flex-wrap: wrap;
}

.btnHome{
    margin-top: 50px;
    color: white;
    width:200px;
    height: 52px;
    border: 1px solid #007bff;
    border-radius: 7px;
    background-color: #007bff;
    /* background-color: rgb(230,237,243) ; */


    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    cursor: pointer;
}
.buttonVideo {
    text-align: center;
}

.btnHome2{
    color: white;
    width: 140px;
    height: 52px;
    border: 1px solid white;
    border-radius: 7px;

    background-color: #00d2ff;


    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    cursor: pointer;
}
video {
    width: 100%;
    height: 400px;
    max-height: 100%;
    display: block;
    margin: 0 auto;
  }
  .videoContainer {
    max-width: 900px;
    margin: 0 auto 30px;
    text-align: left;
  }

@media (max-width:768px){
  
    .videoMain{
    padding: 16px;

        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
    }
   
    .btnHome2{
        width:110px;
        height: 45px;
        font-size: 15px;
    }
    .btnHome{
        margin-top: 80px;
        width:150px;
        height: 45px;
        font-size: 15px;
    }
}

@media (max-width:425px){
    video {
        height: 260px;
      }
    .homeContainer{
    
        padding-left: 10px;
        padding-right: 10px;
    
    }
    .videoMain{
        padding: 10px;
    
            font-size: 17px;
            font-weight: 500;
            line-height: 26px;
        }
}
