*{
    margin: 0;
    padding: 0;
}

.sideBarMain{
    padding: 0rem !important;

    display: flex;
    flex-direction: column !important;
    align-items:center;
    padding-right: 0 !important;

}

.sideMain{
    padding-top: 10px;
    padding-left: 0px;
    padding-bottom: 20px;
    
    width: 290px;
    height: auto;
/* background-color: rgb(13,17,23); */
}
.ButtonMain{
    display: flex !important;
    gap: 0px !important;
    flex-direction: column !important;
    position: fixed;
    width: 240px;
}
.Sidebtn{
    color: white;
    width:100%;
    height: 58px;
    border: 1px solid #007bff;
display: flex;
justify-content: center;
align-items: center;
    background-color: #007bff;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    cursor: pointer;
}

@media (max-width:768px){



    .sideMain{
        margin-top: -20px;
        padding-top: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        
        width: 145px;
    }
    .ButtonMain{
        padding-top: 20px;
        display: flex;
        gap: 10px;
        flex-direction: column;
    }
    .Sidebtn{
        width: 124px;
        height: 46px;
    
        font-size: 16px;
    }
}